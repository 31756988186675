.AuthenticationLayout {
  @apply min-h-screen w-full bg-center bg-cover relative;
}

.AuthenticationLayout__BackgroundInfo {
  @apply text-white px-3 xl:px-4 pb-3 xl:pb-4 bottom-4 md:bottom-6 left-0 w-full sm:w-96 lg:w-80 xl:w-96 h-auto -mt-16 xxxs:-mt-0 lg:fixed;

  .BlurCard {
    @apply grid grid-cols-12 xxxs:grid-cols-8 xs:grid-cols-10 md:grid-cols-7 lg:grid-cols-12 gap-4 xl:gap-2 p-3 xl:p-4;
  }

  .AuthenticationLayout__BackgroundInfo_InfoIcon {
    @apply col-span-2 xxxs:col-span-1 xs:col-span-1 md:col-span-1 lg:col-span-2;
  }

  .AuthenticationLayout__BackgroundInfo_InfoText {
    @apply col-span-10 xxxs:col-span-7 xs:col-span-9 md:col-span-6 lg:col-span-10 text-ellipsis text-sm text-zinc-300 overflow-hidden;

    a {
      @apply text-blue-600;
    }
  }
}

.AuthenticationLayout__MainContent {
  @apply grid grid-cols-1 grid-rows-1 place-content-center place-items-center min-h-screen w-96 pt-32 pb-96 px-2 m-auto max-w-full;

  @media only screen and (min-width: 279px) {
    padding-bottom: 25rem;
  }

  .BlurCard {
    @apply bg-white/90 border-stone-200 p-4 md:p-6 m-auto;
  }

  .AuthenticationLayout__MainContent__LogoWrapper {
    @apply mb-10 -mt-20 xxxs:-mt-24 xxxs:mb-10 xxs:-mt-24 xxs:mb-10 xs:mb-14 xs:-mt-28 sm:mb-14 sm:-mt-28 md:mb-14 md:-mt-28 lg:-mt-28 lg:mb-14;

    .AuthenticationLayout__MainContent__Logo {
      @apply w-full xxs:w-80 xs:w-80 sm:w-80 m-auto;
    }
  }
}
