.Footer {
  font-size: .875rem;
  margin-top: 2.5rem;
  text-align: center;
  color: rgb(212 212 216/1);
  max-width: 100%;

  @media only screen and (min-width: 640px) {
    .Footer {
      width: 24rem;
    }
  }

  @media only screen and (min-width: 279px) {
    margin-bottom: -195px;
  }

  @media only screen and (min-width: 360px) {
    margin-bottom: -195px;
  }

  @media only screen and (min-width: 374px) {
    margin-bottom: -195px;
  }

  @media only screen and (min-width: 389px) {
    margin-bottom: -195px;
  }

  @media only screen and (min-width: 413px) {
    margin-bottom: -195px;
  }

  @media only screen and (min-width: 600px) {
    margin-bottom: -195px;
  }
}
