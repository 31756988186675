//*::-webkit-scrollbar {
//  background-color: transparent !important;
//  width: 8px;
//  height: 8px;
//}

//*::-webkit-scrollbar-thumb {
//  background-color: rgba(0, 0, 0, 0.2);
//  border-radius: 10px;
//}
//
//*::-webkit-scrollbar-corner {
//  background: transparent !important;
//}

html,
body {
  @apply bg-gray-100 w-full overflow-x-hidden;
}

.BlurCard {
  @apply border border-zinc-800 shadow-lg rounded-xl backdrop-blur-md;
}

a {
  @apply text-blue-600;
}
